import React, { Fragment, useState } from 'react'
import { connect } from 'react-redux'
// import { Link } from 'react-router-dom'
import { cartRemove, cartUpdateItem } from '../../redux/actions/cart'
import PromotionCode from './promotionCode'
// import PayPalCheckout from './PayPalCheckout';
import {getMappedIncludes, getRelationshipFromMappedIncludes} from "@centarro/js-sdk";
import CartSummary from "../../blocks/CartSummary";

// s4n
import { useIntl, Link } from 'gatsby-plugin-intl'
import CommerceHelper from './../../../s4n/utils/commerce-helper'
import Preloader from './../../../../Preloader'
import Product from './../../../../../../model/commerce/product'
import ProductImage from './../../../../Shop/Product/image'
import ProductPrice from './../../../../Shop/Product/price'
import ProductQuantityAddToCart from './../../../../Shop/Product/Quantity'
import Breadcrumb from './../../../s4n/Breadcrumb'
import { CHECKOUT_STEP_CART } from './../../../s4n/utils/CheckoutSteps'

import loadable from '@loadable/component'
import withDrupalOauthConsumer from './../../../../../drupal-oauth/withDrupalOauthConsumer';


const CartPage = (props) => {
	const intl = useIntl();
	const t = intl.formatMessage;

	const { 
		cart: { carts, included, cartToken }, dispatch, history,
		userAuthenticated: isUserAuthenticated,
		} = props;

	// console.log(carts)

	if (CommerceHelper.isCartEmpty(props)) {
		return (
		<div className={`container no-items`} key={`no_items`}>
			<div className={`row`}>
				<div className={`col`}>
					<h1>{ t({ id: `soft4net_shop_commerce_cart_page_title` }) }</h1>
					<p>{ t({ id: `soft4net_shop_commerce_cart_page_list_empty` }) }</p>
				</div>
			</div>
		</div>
		)
	}
	const mappedIncludes = getMappedIncludes(included)

	const CheckoutLink = CommerceHelper.getCheckoutLink({
		...props, 
		label: t({ id: `soft4net_shop_commerce_cart_page_summary_next` }),
	})

  return (
    <Fragment>
      {carts.map(cart => {
		  
        const orderItemsRelationship = cart.relationships.order_items
        if (!orderItemsRelationship.data || orderItemsRelationship.data.length === 0) {
          return (
            <div className={`container no-items`} key={`no_items`}>
              <div className={`row`}>
                <div className={`col`}>
					<h1>{ t({ id: `soft4net_shop_commerce_cart_page_title` }) }</h1>
					<p>{ t({ id: `soft4net_shop_commerce_cart_page_list_empty` }) }</p>
				</div>
              </div>
            </div>
          )
		}
		
		const cartItems = getRelationshipFromMappedIncludes(cart, 'order_items', mappedIncludes)
		
        return (
			<div key={cart.id} className={`container`}>
				<div className={`row`}>
					{/* <div className={`col-sm-8`}> */}

					<div className={`col-12`}>
						<Breadcrumb activeStep={CHECKOUT_STEP_CART} />
					</div>

					<div className={`col-12`}>
						<h1>{ t({ id: `soft4net_shop_commerce_cart_page_title` }) }</h1>
						<table className={`table`}>
							<thead>
								<tr>
									<th className="image"></th>
									<th className="title"></th>
									<th className="quantity">
										<span>{ t({ id: `soft4net_shop_commerce_cart_page_list_header_product_quantity` }) }</span>
									</th>
									<th className="price">{ t({ id: `soft4net_shop_commerce_cart_page_list_header_product_price` }) }</th>
									<th className="remove"></th>
								</tr>
							</thead>
							<tbody>
							{cartItems.map(orderItem => {
								const purchaseEntity = getRelationshipFromMappedIncludes(orderItem, 'purchased_entity', mappedIncludes);
								const product = getRelationshipFromMappedIncludes(purchaseEntity, 'product_id', mappedIncludes);
								const variation = getRelationshipFromMappedIncludes(product, 'variations', mappedIncludes);

								// s4n
								const purchasableEntity = purchaseEntity;
								const drupal_internal__product_id = product.attributes.drupal_internal__product_id;
								const commerceProduct = Product.getItemByProductId(drupal_internal__product_id, intl.locale);
								// console.log(commerceProduct)
								const commerceProductPurchasedVariartion = CommerceHelper.getProductPurchasedVariartion(commerceProduct, purchaseEntity);



	// Replace title for package product START
								const aProductsPackage = process.env.SOFT4NET_PRODUCTS_PACKAGE.split(',').map(v => Number(v)); // [601, 602]
								const isProductPackage = aProductsPackage.includes(drupal_internal__product_id);
								const orderItemTitle = isProductPackage ? orderItem.attributes.title.split(` - `)[0] : orderItem.attributes.title;
	// Replace title for package product STOP



								return (
									<tr key={orderItem.id}>
										<td className="image">
											<Link to={product.attributes.path.alias}>
												<ProductImage defaultVariation={commerceProductPurchasedVariartion} />
											</Link>
										</td>
										<td className="title">
											<Link to={product.attributes.path.alias}>
												{/* {purchasableEntity.attributes.title}{` `} */}
												{/* {purchasableEntity.attributes.drupal_internal__variation_id} */}
												{orderItemTitle}
											</Link>
										</td>
										<td className="quantity">
											{/* <input className="form-control" type={`number`} size={5} min={0} defaultValue={parseInt(orderItem.attributes.quantity)} onChange={e => {dispatch(cartUpdateItem(orderItem, e.target.value))}} /> */}
											<ProductQuantityAddToCart quantity={parseInt(orderItem.attributes.quantity)} onQuantityModify={quantity => dispatch(cartUpdateItem(orderItem, quantity))} />
										</td>
										<td className="price">
											{/* {orderItem.attributes.total_price.formatted} */}
											<ProductPrice selectedVariation={purchasableEntity.attributes} _printLabel={true} />
										</td>
										<td className="remove">
											<button className="" onClick={() => { dispatch(cartRemove(orderItem)) }}>
												{ t({ id: `soft4net_shop_commerce_cart_page_list_remove` }) }
											</button>
										</td>
									</tr>
								)
							})}
							</tbody>
							<tfoot>
								<tr className={`cart-summary`}>
									<td colSpan={4}>
										<p className={`mt-3 mb-0`}>{ t({ id: `soft4net_shop_commerce_cart_page_choose_decorative_packaging` }) }</p>
										{(() => {
											const ProductRelated = loadable(() => import(`./../../../../../../components/s4n/Shop/Product/related`));
											return (
												<ProductRelated disableHeader={true} products={process.env.SOFT4NET_PRODUCTS_PACKAGE.split(',').map(productId => Product.getItemByProductId(productId, intl.locale))} limit={2} gridClassName={`col-6 col-lg-3`} />
											)
										})()}
									</td>
									<td className={``}>
										<CartSummary 
											cart={cart} 
										/>
									</td>
								</tr>
							</tfoot>
						</table>

						<div className={`row pb-5`}>
							<div className={`col-lg-6`}>
								<div className={`___well`}>
									<PromotionCode />
								</div>
							</div>
							<div className={`col-lg-6 text-right`}>
								{isUserAuthenticated ?
									<Fragment>
										{CheckoutLink}
									</Fragment>
									:
									<Link to={`/user/login-register?destination=${CommerceHelper.getCheckoutLinkUrl(props)}`} className={`btn btn-success`}>{ t({ id: `soft4net_shop_commerce_cart_page_summary_next` }) }</Link>
								}
							</div>
						</div>

					</div>
					{/* <div className={`col-sm-4 text-right my-4`}>
						<PayPalCheckout cart={cart} cartToken={cartToken} history={history} />
					</div> */}
				</div>
			</div>
        )
      })}
    </Fragment>
  )
};

const mapStateToProps = state => {
  return {
    cart: state.shopReducers.cart,
  }
};

export default connect(mapStateToProps)(withDrupalOauthConsumer(CartPage));